import React, { useEffect, useState } from "react"
import { navigate } from '@reach/router'
import Helmet from "react-helmet"
import swal from 'sweetalert2'
import Loader from 'react-loader-spinner'

import Layout from '../components/layout'


import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import map from '../assets/images/map.png'
import LifeImage from '../assets/images/home/building_4x.jpg'
import Header from "../components/Header"
import { SmallSection, TextSection } from '../components/Section'
import { sendHallForHire } from "../lib/API"


const MultiTimeInputs = ( { times, setTimes, changeTimes } ) => {

    const setTimeArray = ( time, index, field ) => {
        let newTimes
        const newTime = field !== "duration" ? { time, duration: times[ index ].duration } :  { time: times[ index ].time, duration: time }
        if( index > 0 )
            newTimes = [ ...times.slice( 0, index ), newTime, ...times.slice( index + 1 )  ] 
        else
            newTimes =  [ newTime, ...times.slice( 1 )  ] 
        setTimes( newTimes )
        changeTimes( newTimes )
    }

    const addTime = () => {
        setTimes( [ ...times, { time: '', duration: 0 } ] )
    }

    const removeTime = () => {
        setTimes( times.slice( 0, -1 ) )
    }

    return <div className='multi-time-inputs'>
                {times.length > 0 && times.map( ( { time, duration }, i ) => 
                    <span>
                        <input className="form-input" type="datetime-local" required value={time} onChange={e => setTimeArray( e.target.value, i )} />
                        <span><input className="form-input" type="number" required value={duration} onChange={e => setTimeArray( e.target.value, i, "duration" )} />Hour(s)</span>
                        <br/>
                    </span> )}
                <span className='time-buttons'>
                    <span className='time-add-button' onClick={addTime}><i className='fa fa-plus-circle'/></span>
                    {times.length > 1 && <span className='time-remove-button' onClick={removeTime}><i className='fa fa-minus-circle'/></span>}
                </span>
            </div>

}


const HallForHirePage = () => {
    const siteTitle = "Blackheath and Charlton Baptist Church - Hall for Hire"
    const headerImages = [ LifeImage ]
    const headerTitle = 'Hall for Hire'

    const [ values, setValues ] = useState( {} )
    const [ times, setTimes ] = useState( [ { time: '', duration: 0 } ] )
    const [ loading, setLoading ] = useState( false )


    const changeValue = ( e ) => {
        setValues( { 
            ...values,
            [ e.target.name ] : e.target.value
        } )
    }

    const changeTimes = ( newTimes ) => setValues( { ...values, times: newTimes } )

    const submit = e => {
        e.preventDefault()
        setLoading( true )
        // console.log( times )
        // console.log( values )
        console.log( values )
        sendHallForHire( values )
        setLoading( false )
        swal.fire( 'Success', 'Form sent successfully', 'success' ).then( () => {
            Array.from( document.getElementsByTagName( 'input' ) ).forEach( input => {   
                if( input.type !== 'submit' )
                    input.value = ''
            } )
            Array.from( document.getElementsByTagName( 'textarea' ) ).forEach( input => {   
                input.value = ''
            } )

            Array.from( document.getElementsByTagName( 'select' ) ).forEach( input => {   
                input.value = ''
            } )
            // navigate( '/hall-for-hire' )
            window.scrollTo( 0, 0 )
        } )
        
        
    }

  

    return (
        
        <div id='hall-for-hire-page'>
            <Helmet title={siteTitle} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.35} />  

            <TextSection
                id='hall-for-hire-text'
                description={`<b>We offer a variety of rooms for hire, suitable for small, medium, and large groups:</b>

                            <li> Main Church Hall: Ideal for presentations and conferences, seating up to 80 people. </li>
                            <li> Kitchen/Café Space: Available separately. It can be paired with small or medium-sized rooms. </li>
                            <li> Outdoor Patio & Off-Street Parking: Available for all bookings. </li>

                            For more information or to book a space, please contact us using the form below.
                
                `}
            />


            <section id="hall-for-hire-form" className="main style1 special">
                <div className="grid-wrapper">

                    <div className='col-12 hall-for-hire-input'>
                        <form onSubmit={submit}>
                            <div className="grid-wrapper">

                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="text" name="org_name" placeholder="Organisation Name*" required /><br/>
                                </div>
                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="text" name="org_email" placeholder="Email*" required /><br/>
                                </div>
                                <div className='col-12'>
                                    <textarea className="form-input" onChange={changeValue} name="description" placeholder="Description of proposed activity*" required /><br/>
                                </div>
                                
                                <div className='col-12'>
                                    <label>Times Required</label>
                                    <MultiTimeInputs times={times} setTimes={setTimes} changeTimes={changeTimes} />
                                </div>

                                <div className='col-12'>
                                    <label>Rooms</label>
                                    <textarea className="form-input" onChange={changeValue} name="rooms" placeholder="Room(s) required*" required /><br/>
                                </div>

                                <div className='col-12'>
                                    <label>Correspondent Information</label>
                                </div>
                                
                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="text" name="correspondant_name" placeholder="Name of correspondent*" required/><br/>
                                </div>
                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="tel" name="correspondant_telephone" placeholder="Correspondent contact number*" required/><br/>
                                </div>
                                <div className='col-12'>
                                    <textarea className="form-input" onChange={changeValue} name="correspondant_address" placeholder="Correspondent address*" required /><br/>
                                </div>

                                <div className='col-12'>
                                    <label>Event Supervisor Information</label>
                                </div>

                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="text" name="supervisor_name" placeholder="Name of supervisor*" required/><br/>
                                </div>
                                <div className='col-6'>
                                    <input className="form-input" onChange={changeValue} type="tel" name="supervisor_telephone" placeholder="Supervisor contact number*" required/><br/>
                                </div>
                                <div className='col-12'>
                                    <textarea className="form-input" onChange={changeValue} name="supervisor_address" placeholder="Supervisor address*" required /><br/>
                                </div>

                                <div className='col-12'>
                                    <label>In the case of activity involving children has your Organisation agreed to comply with the Government’s suggested Guidelines 
                                    <br/>‘Working Together to Safeguard Children 2015’?</label>
                                    <select name='complying' onChange={changeValue} required>
                                        <option value="" selected disabled hidden>--- Choose here ---</option>
                                        <option className='form-input' value='yes'>Yes</option>
                                        <option className='form-input' value='no'>No</option>
                                    </select>
                                </div>

                                <div className='col-12'>
                                    <label>Is your organisation a charity?</label>
                                    <select name='is_charity' onChange={changeValue} required>
                                        <option value="" selected disabled hidden>--- Choose here ---</option>
                                        <option className='form-input' value='yes'>Yes</option>
                                        <option className='form-input' value='no'>No</option>
                                    </select>
                                </div>
                                

                                

                                <div className='col-12'>
                                <br />{!loading ? <input type="submit" value="Submit" className="button special" /> :
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={40}
                                    width={50}
                                />}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HallForHirePage